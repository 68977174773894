#main-footer {
  .footer-link {
    padding: 50px 0;
    background: #27292E;
    color: #fff;
    &__title {
      margin-bottom: 40px;
      padding-left: 15px;
      font-size: 18px;
      line-height: 25px;
    }
    &__list {
      padding-left: 15px;
      font-size: 15px;
      color: #9DA0A5;
      &_item {
        position: relative;
        margin-bottom: 20px;
        a {
          color: #9DA0A5;
        }
        &_span--title {
          width: 75px;
        }
        &_span--content {
          position: absolute;
          top: 0;
          left: 75px;
        }
      }
    }
    &__app_code {
      margin-left: 15px;
      width: 82px;
      height: 82px;
      background: url('/imgs/app_qrcode.png') no-repeat;
      background-size: 100%;
    }
    &__app_code_text {
      padding-left: 15px;
      margin-top: 5px;
      font-size: 12px;
      color: #9DA0A5;
    }
  }
  .footer-copyright {
    margin: 0 auto;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 14px;
    color: #6C7179;
    background-color: #000;
    p {
      display: inline-block;
    }
  }
}